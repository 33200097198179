<template>
  <div class="saleInvoice">
    <h1>销售发票</h1>
    <el-form :inline="true">
      <el-form-item label="发票日期">
        <el-date-picker v-model="searchParam.invoiceDates" type="daterange" start-placeholder="起" end-placeholder="止" style="width: 220px;" />
      </el-form-item>
      <el-form-item label="客户">
        <el-select v-model="searchParam.custom" filterable style="width: 120px;" clearable>
          <el-option v-for='customItem in show.customList' :key="customItem.id" :label="customItem.value" :value="customItem.value" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="primary" @click="commitFindList()">搜索</el-button>
          <el-button @click="resetSearchParam()">重置</el-button>
        </el-button-group>
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="primary" @click="add()">新建</el-button>
          <el-button type="warning" :disabled="!(show.selected.length == 1)" @click="update()">详情</el-button>
          <el-button type="danger" :disabled="!(show.selected.length >= 1)" @click="del()">删除</el-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-table :data="data.list" @selection-change="selectionChange()" ref="tabRef" border show-summary :summary-method="summary" style="height: calc(100vh - 310px);">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column width="55" align="center">
        <template #default="scope">
          <el-link type="primary" @click="update(scope.row)">详情</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="invoiceDate" label="开票日期" width="120" show-overflow-tooltip />
      <el-table-column prop="custom" label="客户" show-overflow-tooltip />
      <el-table-column prop="invoiceQuantity" label="煤矿数量" show-overflow-tooltip />
      <el-table-column prop="invoiceAmount" label="发票金额" show-overflow-tooltip />
    </el-table>
    <div style="height: 10px;" />
    <el-pagination v-model:current-page="searchParam.pageNum" v-model:page-size="searchParam.pageSize" :page-sizes="[50, 100, 200]" background layout="total, sizes, prev, pager, next, jumper" :total="data.total" @change="commitFindList()" />
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import cache from '../../utils/cache'
import util from '../../utils/util'

const router = useRouter()
const tabRef = ref()

const data = reactive({
  total: 0,
  list: [],
  summary: {},
})

const show = reactive({
  selected: [],
  customList: [],
})

api.get('/backend/dict/getByCode', { params: { code: 'custom' } }).then(res => {
  show.customList = res.dictList
})

const searchParam = reactive({})
const resetSearchParam = () => {
  searchParam.pageNum = 1
  searchParam.pageSize = 50
  searchParam.invoiceDates = []
  searchParam.reviewState = null
}
resetSearchParam()
const beforeSearchParam = cache.getObject(cache.keys.pageParam + 'saleInvoice')
if (beforeSearchParam) {
  Object.assign(searchParam, beforeSearchParam)
  cache.setObject(cache.keys.pageParam + 'saleInvoice', null)
}

const commitFindList = () => {
  const params = {}
  params.pageNum = searchParam.pageNum
  params.pageSize = searchParam.pageSize
  if (searchParam.invoiceDates && searchParam.invoiceDates.length == 2) {
    params.invoiceDateGe = util.parseTime(searchParam.invoiceDates[0], '{y}-{m}-{d}')
    params.invoiceDateLe = util.parseTime(searchParam.invoiceDates[1], '{y}-{m}-{d}')
  }
  params.custom = searchParam.custom
  api.get('/backend/saleInvoice/page', { params: params }).then(res => {
    data.list = res.list
    data.total = res.total
    data.summary = res.data
  })
}
commitFindList()

const summary = () => {
  return ['合计', '', '', '', data.summary.invoiceQuantity, data.summary.invoiceAmount]
}

const selectionChange = () => {
  show.selected = tabRef.value.getSelectionRows()
}

const add = () => {
  cache.setObject(cache.keys.pageParam + 'saleInvoice', searchParam)
  cache.setObject(cache.keys.pageParam + 'saleInvoiceDetail', null)
  router.push('/saleInvoiceDetail')
}

const update = (row) => {
  cache.setObject(cache.keys.pageParam + 'saleInvoice', searchParam)
  const saleInvoice = row ? row : tabRef.value.getSelectionRows()[0]
  cache.setObject(cache.keys.pageParam + 'saleInvoiceDetail', saleInvoice)
  router.push('/saleInvoiceDetail')
}

const del = () => {
  const ids = tabRef.value.getSelectionRows().map(saleInvoice => saleInvoice.id)
  ElMessageBox.confirm('', {
    title: '确认删除',
    message: '将要删除选中的 ' + ids.length + ' 项。',
    autofocus: false,
    confirmButtonText: '确认',
    cancelButtonText: '取消'
  }).then(() => {
    api.post('/backend/saleInvoice/del', { ids: ids }).then(() => {
      ElMessage.success('删除成功')
      commitFindList()
    })
  })
}
</script>

<style lang="less"></style>